import axios from 'axios';

import FlashMessageInit from './flash-message';

import { Modal } from '../utils';

const EmailSmsTemplateInit = () => {
  if (document.querySelector('#main-nav-template-link')) {
    new EmailSmsTemplateModal();
  }
};

export default EmailSmsTemplateInit;

class EmailSmsTemplateModal {
  constructor() {
    this.body = document.querySelector('body');
    // Main navigation modal of this site
    this.mainNav = document.querySelector('#main-nav');
    this.mainNavLink = document.querySelector('#main-nav-template-link');

    // Overlay
    this.loadingScreen = document.querySelector('.loading-screen');

    // Email/Sms Template Modal
    this.modal = document.querySelector('#template-modal');
    this.modalToggles = this.modal.querySelectorAll("[name='nav']");
    this.modalCloseBtn = this.modal.querySelector('#template-modal-close-btn');
    this.modalResetBtns = this.modal.querySelectorAll('.template-modal__reset-btn');
    this.modalForms = this.modal.querySelectorAll('form.template-modal__body');
    this.modalEmailField = this.modal.querySelector("[name='email-text']");
    this.modalEmailTitle = this.modal.querySelector("[name='email-title']");
    this.modalSelector = this.modal.querySelector('select');

    // Modal controls
    this.modalListeners = new Modal(this.modal);

    this.popup = document.querySelector('.template-names-modal');
    this.popupInput = this.popup.querySelector('input');
    this.popupList = this.popup.querySelector('ul');

    this.setAllListeners();

    // CONST
    this.currentModal = 'modalEmail';
    this.currentEmailName = 'email_registration';
    this.currentSmsName = 'sms_registration';
    this.emailTemplateLoad = false;
    this.smsTemplateLoad = false;
    this.startKey = '[';
    this.templateNameKeyTrigger = false;
    this.templateNameValueTrigger = true;
    this.templateNameValue = '';
    this.templateNames = [
      'NAME_PATIENT',
      'NAME_HOSPITAL',
      'URL_REGISTER',
      'URL_CANTAB',
      'URL_QR_CANTAB',
      'URL_QUESTIONNAIRE',
      'URL_QR_QUESTIONNAIRE',
      'URL_ONLY_CANTAB',
      'URL_SURVEY_MITAKA',
      'URL_MITAKA_PHONE_CHECK',
      'URL_MITAKA_QUESTIONAIRE',
      'CONSULTATION_URL',
      'URL_MY_PAGE',
      'URL_MY_PAGE_REPORT',
      'BRAIN_ID',
      'MYPAGE_PWD',
    ];
  }

  setAllListeners() {
    this.openModalListener();
    this.closeModalListener();
    this.modalTextFieldListener();
    this.templateNameListener();
    this.popupInputListener();
    this.selectorListener();
    this.resetTemplateListener();
    this.saveTemplateListener();
    this.templateTypeListener();
  }

  openModalListener() {
    this.mainNavLink.addEventListener('click', () => {
      this.modal.style.display = 'flex';
      this.mainNav.checked = false;
      this.body.style.overflow = 'hidden';
      this.modalListeners.addListener();
      this.loadTemplateListener(false, this.modalSelector.value).then((data) => {
        this.setTemplate(data);
        this.emailTemplateLoad = this.modalSelector.value;
      });
    });
  }

  closeModalListener() {
    this.modalCloseBtn.addEventListener('click', () => {
      this.modal.style.display = 'none';
      this.modalListeners.removeListener();
      if (document.querySelector('body').classList.contains('base-patients-list')) {
        this.body.style.overflow = 'hidden';
      } else {
        this.body.style.overflow = 'unset';
      }
    });
  }

  resetTemplateListener() {
    [...this.modalResetBtns].forEach((elem) =>
      elem.addEventListener('click', () => {
        this.loadTemplateListener(true, this.currentModal === 'modalEmail' ? this.currentEmailName : this.currentSmsName).then((data) =>
          this.setTemplate(data),
        );
      }),
    );
  }

  saveTemplateListener() {
    [...this.modalForms].forEach((elem) =>
      elem.addEventListener('submit', (evt) => {
        evt.preventDefault();
        this.loadingScreen.style.display = 'block';

        const data = new FormData();
        this.currentModal === 'modalEmail' ? data.append('name', this.currentEmailName) : data.append('name', this.currentSmsName);
        this.currentModal === 'modalEmail' ? data.append('title', this[`${this.currentModal}Title`].value) : data.append('title', '');
        data.append('text', this.parseTemplateText());

        axios
          .post(this.modal.dataset.link, data)
          .then((response) => this.setMessage(true, response.data.msgUser))
          .catch(() => this.setMessage(false, 'Some error'));
      }),
    );
  }

  async loadTemplateListener(isDefault, name) {
    if ((this.currentModal === 'modalEmail' ? this.emailTemplateLoad !== name : this.smsTemplateLoad !== name) || isDefault) {
      this.loadingScreen.style.display = 'block';
      const response = await axios.get(this.modal.dataset.link, { params: { default: isDefault, name: name } });
      return response.data;
    } else {
      return false;
    }
  }

  selectorListener() {
    this.modalSelector.addEventListener('change', (evt) => {
      this.loadTemplateListener(false, evt.target.value).then((data) => {
        this.setTemplate(data);
        this.currentEmailName = evt.target.value;
        this.emailTemplateLoad = evt.target.value;
      });
    });
  }

  modalTextFieldListener() {
    [this.modalEmailField].forEach((elem) =>
      elem.addEventListener('keyup', (evt) => {
        if (this.templateNameValueTrigger && evt.key === this.startKey) {
          this.setTemplateName();
          this.fillPopupList();
          this.showPopup();
          this.templateNameValueTrigger = false;
        }
      }),
    );
  }

  popupInputListener() {
    this.popupInput.addEventListener('input', (evt) => {
      const target = evt.target;

      this.templateNameValue = target.value;

      this.fillPopupList();
      console.log(this.templateNameValue);
    });
  }

  templateNameListener() {
    this.popup.addEventListener('click', (evt) => {
      const target = evt.target;
      if (target.tagName === 'LI') {
        const newSpan = document.createElement('span');
        newSpan.innerText = target.innerText;
        newSpan.classList.add('template-modal__finish');
        // eslint-disable-next-line no-script-url
        newSpan.setAttribute('onclick', 'javascript:this.remove();');
        newSpan.setAttribute('contenteditable', 'false');
        newSpan.setAttribute('data-label', 'On click this element would be deleted.');

        this.span.parentNode.replaceChild(newSpan, this.span);

        this.hidePopup();
        this.setDefaultSettings();
        this[`${this.currentModal}Field`].focus();
      }
    });
  }

  templateTypeListener() {
    [...this.modalToggles].forEach((elem) => {
      elem.addEventListener('change', (evt) => {
        if (evt.target.checked) this.currentModal = evt.target.dataset.type;
        this.loadTemplateListener(false, this.currentModal === 'modalEmail' ? this.currentEmailName : this.currentSmsName).then((data) => {
          this.setTemplate(data);
          this.currentModal === 'modalEmail' ? (this.emailTemplateLoad = this.currentEmailName) : (this.smsTemplateLoad = this.currentSmsName);
        });
      });
    });
  }

  checkTemplateNames() {
    return this.templateNames.filter((name) => name.includes(this.templateNameValue.toUpperCase()));
  }

  setTemplate(data) {
    if (data) {
      if (this[`${this.currentModal}Title`]) this[`${this.currentModal}Title`].value = data.title;
      this[`${this.currentModal}Field`].innerHTML = data.text;
      this.parseTemplateNames();
      setTimeout(() => (this.loadingScreen.style.display = 'none'), 300);
    }
  }

  setTemplateName() {
    this[`${this.currentModal}Field`].innerHTML = this[`${this.currentModal}Field`].innerHTML.replace(
      this.startKey,
      "<span id='replacer-template'></span>",
    );
    this.span = this[`${this.currentModal}Field`].querySelector('#replacer-template');
  }

  parseTemplateNames() {
    this.templateNames.forEach((name) => {
      const re = new RegExp(String.raw`\b${name}\b`, 'g');
      this[`${this.currentModal}Field`].innerHTML = this[`${this.currentModal}Field`].innerHTML.replace(
        re,
        `<span class="template-modal__finish" onclick="javascript:this.remove();" contenteditable="false" data-label="On click this element would be deleted.">${name}</span>`,
      );
    });
  }

  parseTemplateText() {
    return this[`${this.currentModal}Field`].innerText.replaceAll(/\n/g, '<br>');
  }

  showPopup() {
    this.popup.classList.add('template-names-modal--show');
    this.popupInput.focus();
  }

  hidePopup() {
    this.popup.classList.remove('template-names-modal--show');
    this.popupInput.value = '';
  }

  fillPopupList() {
    this.popupList.innerHTML = '';

    this.checkTemplateNames().forEach((name) => {
      const li = document.createElement('li');
      li.innerText = name;
      this.popupList.appendChild(li);
    });
  }

  setMessage(isSuccess, text) {
    const message = document.createElement('div');
    message.id = 'flash-message';
    message.classList.add('flash-message');
    message.classList.add(isSuccess ? 'flash-message--success' : 'flash-message--error');
    message.innerText = text;
    this.body.appendChild(message);
    setTimeout(() => (this.loadingScreen.style.display = 'none'), 300);
    FlashMessageInit();
  }

  setDefaultSettings() {
    this.span.remove();
    this.span = null;

    this.templateNameKeyTrigger = false;
    this.templateNameValueTrigger = true;
    this.templateNameValue = '';
  }
}
