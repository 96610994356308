import React, { FC } from 'react';

import { TDataSet } from '../types';

type TConfirmationPayForReportModal = {
  dataset: TDataSet;
  isDisabled: boolean;
  setIsDisabled: (value: boolean) => void;
  removeModal: () => void;
};

const ConfirmationPayForReportModal: FC<TConfirmationPayForReportModal> = ({ dataset, removeModal }) => {
  const { onAccept, onReject } = dataset;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', alignItems: 'center' }}>
      <h2 className='confirmation-report__title'>確定しますか？</h2>
      <p className='confirmation-report__text'>確定をクリックするとMyBrainへレポートが掲載されます。</p>
      <div style={{ display: 'flex', gap: '1rem' }}>
        <button
          className='btn btn--additional'
          type='button'
          onClick={() => {
            if (onReject) onReject();
            removeModal();
          }}
        >
          キャンセル
        </button>
        <button
          className='btn btn--main'
          type='button'
          onClick={() => {
            if (onAccept) onAccept();
            removeModal();
          }}
        >
          確定
        </button>
      </div>
    </div>
  );
};

export default ConfirmationPayForReportModal;
