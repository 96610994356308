import React, { FC } from 'react';

import { TDataSet } from '../types';

type TConfirmationReportA = {
  dataset: TDataSet;
  isDisabled: boolean;
  setIsDisabled: (value: boolean) => void;
  removeModal: () => void;
};

const ConfirmationReportA: FC<TConfirmationReportA> = ({ dataset, isDisabled, setIsDisabled, removeModal }) => (
  <form className='confirmation-report' action={dataset.link} method='POST' onSubmit={() => setIsDisabled(true)}>
    <h2 className='confirmation-report__title'>海馬測定レポートを確定しますか？</h2>
    <ul>
      <li className='confirmation-report__item'>海馬測定レポートが、MyBrainに掲載されます。</li>
      <li className='confirmation-report__item'>
        受診者の登録メールアドレス宛に、レポート掲載のお知らせが配信されます。
        <br />
        メールアドレスを登録していない場合、または、配信を許可していない場合、お知らせは届きません。
        <br />
        その場合は印刷をして、結果レポートをお渡しください。
      </li>
    </ul>
    <div className='confirmation-report__btn-wrapper'>
      <button className='btn btn--additional btn--fix' type='button' onClick={removeModal} disabled={isDisabled}>
        キャンセル
      </button>
      <button className={`btn btn--main btn--fix ${isDisabled ? 'btn--loading' : ''}`} type='submit' disabled={isDisabled}>
        確定
      </button>
    </div>
  </form>
);

export default ConfirmationReportA;
