import axios from 'axios';
import React, { FC, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import FlashMessageCreate from './FlashMessage';
import RegistrationModalMriResult from './RegistrationModalMriResult';
import RegistrationModalMriUpload from './RegistrationModalMriUpload';

import { SIZE_LIMIT_BYTES } from '../const';
import { TDataSet } from '../types';

type TRegistrationModalMri = {
  dataset: TDataSet;
  setIsBehind: (value: boolean) => void;
  isDisabled: boolean;
  setIsDisabled: (value: boolean) => void;
  setBeforeClose: (value: { flag: boolean; message: string }) => void;
  removeModal: () => void;
  anonymizeDataToggle?: boolean;
};

export type TMriData = {
  name?: string;
  birthDate?: string;
  hospitalPatientId?: string;
  gender?: string;
  uuidDicom?: string;
  uuidNii?: string;
  hintMsg?: Array<string>;
  acqdate?: string;
  embeddedLpixelReport: 'accepted' | 'rejected' | 'not-provided';
};

const RegistrationModalMri: FC<TRegistrationModalMri> = ({
  dataset,
  setIsBehind,
  isDisabled,
  setIsDisabled,
  setBeforeClose,
  removeModal,
  anonymizeDataToggle,
}) => {
  const [prePatientInfo, setPrePatientInfo] = useState<string | undefined>(undefined);
  const [mriData, setMriData] = useState<TMriData>();
  const [files, setFiles] = useState<FileList>();
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(dataset.loading ?? false);
  const firstUpdate = useRef(true);
  const prevPercent = useRef(0);

  const progressBarHandler = (evt: ProgressEvent, totalSize: number) => {
    const { loaded } = evt;
    const currentPercent = Math.floor((loaded / totalSize) * 100);
    setProgress(prevPercent.current + currentPercent);
  };

  useEffect(() => {
    // Prevents run useEffect on initial render.
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    const UUID = uuidv4();
    let size = 0;
    let totalSize = 0;
    let offset = 0;
    let dicomPartIndex = 0;

    const dicomFilesAll: Array<File> = Array.from(files !== undefined ? files : []);
    const dicomFilesParts: Array<Array<File>> = [];

    if (dicomFilesAll.length) {
      for (let i = 0; i < dicomFilesAll.length; i++) {
        size += dicomFilesAll[i].size;
        totalSize += dicomFilesAll[i].size;

        if (size >= SIZE_LIMIT_BYTES || i === 1) {
          dicomFilesParts.push(dicomFilesAll.slice(offset, i));

          size = 0;
          offset = i;
        }

        if (dicomFilesAll.length - 1 === i) {
          dicomFilesParts.push(dicomFilesAll.slice(offset));
        }
      }

      const sendDicomParts = (part: Array<File>) => {
        setBeforeClose({ flag: true, message: 'アップロードを中止しますか？' });
        setIsDisabled(true);
        setIsLoading(true);
        const dicomData = new FormData();
        const isFinal = dicomPartIndex === dicomFilesParts.length - 1;
        part.forEach((elem) => dicomData.append('dicom', elem));

        // Saves previous progress bar percentage.
        prevPercent.current = parseInt(
          document.querySelector<HTMLSpanElement>('.registration-modal__loading-progress-bar span')?.innerHTML as string,
          10,
        );

        axios({
          method: 'post',
          url: '/api/hospital/mri-drag-drop',
          data: dicomData,
          headers: {
            'Content-Type': 'multipart/form-data',
            'Content-Part': isFinal ? 'final' : 'part',
            'Content-Id': UUID,
          },
          onUploadProgress: (evt: ProgressEvent) => progressBarHandler(evt, totalSize),
        })
          .then(async (response) => {
            setPrePatientInfo(response.data.earlyInfo);

            dicomPartIndex += 1;

            // Final part was uploaded.
            if (dicomPartIndex === dicomFilesParts.length) {
              setIsLoading(false);
              setProgress(0);
              prevPercent.current = 0;
              const { data } = response;
              setBeforeClose({ flag: false, message: '' });
              setMriData(data);
              setIsDisabled(false);
              setPrePatientInfo(undefined);
              return;
            }

            sendDicomParts(dicomFilesParts[dicomPartIndex]);
          })
          .catch((err) => {
            setBeforeClose({ flag: false, message: '' });
            setIsDisabled(false);
            setIsLoading(false);
            setProgress(0);
            setPrePatientInfo(undefined);
            prevPercent.current = 0;

            let message =
              '予期しないエラーが発生したため、解析ができません。<br>下記サポートセンターへメールにてご連絡ください。<br><b>E-Mail:</b><a href=""mailto:support@cogsmart-global.com">support@cogsmart-global.com</a>';

            if (err.response && err.response.data && err.response.data.msgUser) {
              message = `MRI processing failed!<br><b>${err.response.data.msgUser}<b>`;
            }

            FlashMessageCreate({
              message,
              type: err.response.data.msgType,
              isSelfDestroyable: false,
              onAccept() {},
            });
          });
      };

      sendDicomParts(dicomFilesParts[dicomPartIndex]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  return (
    <>
      {mriData ? (
        <RegistrationModalMriResult
          mriData={mriData}
          isDisabled={isDisabled}
          removeModal={removeModal}
          setBeforeClose={setBeforeClose}
          setIsBehind={setIsBehind}
          setIsDisabled={setIsDisabled}
          anonymizeDataToggle={anonymizeDataToggle}
        />
      ) : (
        <RegistrationModalMriUpload
          prePatientInfo={prePatientInfo}
          isLoading={isLoading}
          progress={progress}
          isDisabled={isDisabled}
          setFiles={setFiles}
        />
      )}
    </>
  );
};

export default RegistrationModalMri;
